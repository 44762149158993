import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Section from "components/Section";
import UniqueSellingPoint from "components/UniqueSellingPoint";
import useIsInEditMode from "hooks/useIsInEditMode";
import { addEditAttributes } from "utils/episerver";

import {
    Container,
    UniqueSellingPoints,
} from "./UniqueSellingPointList.styled";
import UniqueSellingPointListProps from "./UniqueSellingPointListProps";

const UniqueSellingPointList = ({
    identifier,
    theme,
    uniqueSellingPoints,
    anchorName,
}: UniqueSellingPointListProps): ReactElement => {
    const isInEditMode = useIsInEditMode();

    return (
        <Section id={anchorName} theme={theme} alignItems="center">
            <ThemeProvider
                theme={{
                    theme: theme,
                }}
            >
                <Container
                    key={identifier || `UniqueSellingPointListContent`}
                    {...addEditAttributes("UniqueSellingPoints")}
                >
                    <UniqueSellingPoints
                        $count={
                            uniqueSellingPoints ? uniqueSellingPoints.length : 0
                        }
                    >
                        {uniqueSellingPoints?.map((usp, index) => {
                            usp.heading =
                                usp.heading || (isInEditMode ? "" : undefined);
                            usp.text =
                                usp.text || (isInEditMode ? "" : undefined);

                            return (
                                <UniqueSellingPoint
                                    key={`UniqueSellingPoint${index}`}
                                    identifier={
                                        anchorName
                                            ? `${anchorName}-${index}`
                                            : identifier
                                              ? `${identifier}-${index}`
                                              : ""
                                    }
                                    heading={usp.heading}
                                    text={usp.text}
                                />
                            );
                        })}
                    </UniqueSellingPoints>
                </Container>
            </ThemeProvider>
        </Section>
    );
};

export default React.memo(UniqueSellingPointList);
