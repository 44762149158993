import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import UniqueSellingPointProps from "./UniqueSellingPointProps";

const UniqueSellingPoint = loadable(
    (): Promise<DefaultComponent<UniqueSellingPointProps>> =>
        import(
            /* webpackChunkName: "UniqueSellingPoint" */ "./UniqueSellingPoint"
        ),
);
const UniqueSellingPointLoader = (
    props: UniqueSellingPointProps,
): ReactElement => <UniqueSellingPoint {...props} />;
export default UniqueSellingPointLoader;
