import { styled, css } from "styled-components";

import { Container as UniqueSellingPoint } from "components/UniqueSellingPoint/UniqueSellingPoint.styled";
import { Container as GridContainer } from "style/components/Page";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding-bottom: 3.5rem;
    padding-top: 2.625rem;
    position: relative;

    ${MQ.FROM_M} {
        padding-bottom: 4.375rem;
        padding-top: 4.375rem;
    }

    ${MQ.FROM_L} {
        padding-bottom: 4.375rem;
        padding-top: 4.375rem;
    }

    ${MQ.FROM_XL} {
        padding-bottom: 6.5625rem;
        padding-top: 6.5625rem;
    }
`;

export const UniqueSellingPoints = styled.div.attrs<{
    $count: number;
}>(() => ({}))`
    ${columnGap};
    display: grid;
    grid-template-columns: repeat(1, [col-start] 1fr);
    row-gap: 2.1875rem;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(2, [col-start] 1fr);
        row-gap: 2.625rem;
    }

    ${MQ.FROM_L} {
        row-gap: 2.625rem;
        grid-template-columns: repeat(3, [col-start] 1fr);
        ${(props) =>
            props.$count == 4
                ? css`
                      ${UniqueSellingPoint}:nth-child(3) {
                          grid-column: 2;
                      }
                  `
                : props.$count == 5
                  ? css`
                        ${UniqueSellingPoint}:nth-child(4) {
                            grid-column: 2;
                        }
                    `
                  : ""}
    }

    ${MQ.FROM_XL} {
        row-gap: 4.375rem;
    }
`;
