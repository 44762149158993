import { styled } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import { styleBodyM, styleHeadingL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

const iconColor = theme("theme", {
    lightgray: "--icon-on-neutral-primary",
    cyan: "--icon-on-cyan-primary",
    blue: "--icon-on-blue-primary",
});

export const Container = styled.div`
    column-gap: ${columnGap};
    display: grid;
    grid-auto-rows: auto auto 1fr;
    grid-template-columns: repeat(4, [col-start] 1fr);

    ${MQ.FROM_M} {
        grid-template-columns: repeat(5, [col-start] 1fr);
    }

    ${MQ.FROM_L} {
        grid-template-columns: repeat(4, [col-start] 1fr);
    }
`;

export const Check = styled(Icon)`
    color: var(${iconColor});
    grid-column: col-start 1 / span 4;
    grid-row: 1;
    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 5;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 4;
    }
`;

export const Heading = styled.h2`
    ${styleHeadingL}
    grid-column: col-start 1 / span 3;
    grid-row: 2;
    margin-bottom: 0.875rem;
    margin-top: 1.3125rem;

    ${MQ.FROM_M} {
        margin-top: 1.75rem;
        grid-column: col-start 1 / span 4;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 3;
    }

    ${MQ.FROM_XL} {
        margin-bottom: 1.3125rem;
        margin-top: 2.625rem;
    }
`;

export const Text = styled.div`
    ${styleBodyM};
    grid-column: col-start 1 / span 4;
    grid-row: 3;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 5;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 4;
    }
`;
